import { useEffect, useState } from "react";
import Select from "react-select";
import Form from "../../../Partials/Forms/Form";
import NumberInput from "../../../Partials/Forms/FormInputs/NumberInput/NumberInput";
import FormSubmitButton from "../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";
import { SERVICES_PACKAGES_API } from "../../../../Utilities/APIs/ServicePackagesAPIs/ServicePackagesAPIs";
import SwitchInput from "../../../Partials/Forms/FormInputs/SwitchInput/SwitchInput";
import { useGetInfo } from "antopolis-react-utils/hooks";

function UpdateServicePackage({ setShowUpdateForm, targetID, triggerFetch }) {
  const [type, setType] = useState("");
  const [price, setPrice] = useState("");
  const [isActive, setIsActive] = useState(true); // Default to true if active
  const [error, setError] = useState(""); // For displaying errors
  const [isLoading, setIsLoading] = useState(false); // For loading state

  const axiosInstance = useAxiosInstance();

  const { info: singlePackage } = useGetInfo({
    axiosInstance: axiosInstance,
    api: `${SERVICES_PACKAGES_API}/${targetID}`,
  });

  useEffect(() => {
    if (singlePackage) {
      setType(singlePackage.type);
      setPrice(singlePackage.price);
      setIsActive(singlePackage.isActive);
    }
  }, [singlePackage]);

  async function handleSubmit(e) {
    e.preventDefault();

    // Reset error state before submission
    setError("");
    setIsLoading(true);

    const itemData = {
      type,
      price,
      isActive
    };

    try {
      const response = await axiosInstance.patch(`${SERVICES_PACKAGES_API}/${targetID}`, itemData);
      console.log(response, 'res');

      if (response) {
        triggerFetch();
        setShowUpdateForm(false);
      }
    } catch (error) {
      console.error("Error updating service package:", error);
      setError("An error occurred while updating the service package. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }
  
  return (
    <div className="create_form">
      <Form onSubmit={handleSubmit}>
{/* 
        <Select
          options={formattedOptions}
          value={formattedOptions.find(option => option.value === type)}
          onChange={(selected) => setType(selected?.value || "")}
          placeholder="Select an option"
        /> */}
        <NumberInput
          label={"Enter Price"}
          value={price}
          setState={setPrice}
          placeholder={"Enter Price"}
        />

        <SwitchInput
          label={'Is Active'}
          toggleSwitch={() => setIsActive(prevState => !prevState)}
          checked={isActive}
        />
        {error && <div
          style={{ color: "red", fontSize: "14px" }}>
          {error}
        </div>}

        <FormSubmitButton text={isLoading ? "Updating..." : "Update Package"} />
      </Form>
    </div>
  );
}

export default UpdateServicePackage;
