import { useEffect, useState } from "react";
import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../../Partials/Forms/Form";
import ImageInput from "../../../../Partials/Forms/FormInputs/ImageInput/ImageInput";
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import { OURWORKS_ASSETS_API } from "../../../../../Utilities/APIs/OurWorksAPIs/OurWorksAPIs";
import LongTextInput from "../../../../Partials/Forms/FormInputs/LongTextInput/LongTextInput";



function UpdateWorkAsset({ api, workID, targetID, setShowUpdateForm, triggerFetch }) {
  const [image, setImage] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const axiosInstance = useAxiosInstance();

  const { info: asset } = useGetInfo({
    axiosInstance: axiosInstance,
    api: `${OURWORKS_ASSETS_API}/${targetID}`,
  });

  useEffect(() => {
    if (asset) {
      setImage(asset.image);
      setTitle(asset.title);
      setDescription(asset.description);
    }
  }, [asset]);

  async function handleSubmit(e) {
    e.preventDefault();

    const formData = new FormData();
    formData.append("image", image);
    formData.append("title", title);
    formData.append("description", description);

    const response = await axiosInstance.patch(`${OURWORKS_ASSETS_API}/${targetID}`, formData);
    if (response) {
      triggerFetch();
      setShowUpdateForm(false);
    }
  }

  return (
    <div className="update_form">
      <Form onSubmit={handleSubmit}>
        <ImageInput
          fieldId="1"
          state={image}
          setState={setImage}
          allowUpdateImage
        >
          Update Image
        </ImageInput>

        <ShortTextInput
          label={"Title"}
          value={title}
          setState={setTitle}
          placeholder={"Enter Title"}
        />

        <LongTextInput
          label={"Description"}
          value={description}
          setState={setDescription}
          placeholder={"Enter Description"}
        />

        <FormSubmitButton text="Update Asset" />
      </Form>
    </div>
  );
}

export default UpdateWorkAsset;
