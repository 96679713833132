import { useEffect, useState } from "react";
import Select from "react-select";
import { useGetInfo } from "antopolis-react-utils/hooks";
import Form from "../../../Partials/Forms/Form";
import FormSubmitButton from "../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";
import { SUBSCRIPTION_REQUESTS_API } from "../../../../Utilities/APIs/SubscriptionAPIs/SubscriptionAPIs";
import SelectInput from "../../../Partials/Forms/FormInputs/SelectInput/SelectInput";
import SelectOption from "../../../Partials/Forms/FormInputs/SelectInput/SelectOption/SelectOption";

function UpdateSubscriptionRequest({ api, setShowUpdateForm, targetID, triggerFetch }) {

    const [status, setStatus] = useState("pending");

    const axiosInstance = useAxiosInstance();

    const { info: subscriptionRequest } = useGetInfo({
        axiosInstance: axiosInstance,
        api: SUBSCRIPTION_REQUESTS_API + targetID,
    });

    useEffect(() => {
        if (subscriptionRequest) {
            setStatus(subscriptionRequest.status);
        }
    }, [subscriptionRequest]);

    async function handleSubmit(e) {
        e.preventDefault();

        const response = await axiosInstance.patch(SUBSCRIPTION_REQUESTS_API + targetID, { status });
        if (response) {
            triggerFetch();
            setShowUpdateForm(false);
        }
    }


    return (
        <div className="update_form">
            <Form onSubmit={handleSubmit}>

                <SelectInput
                    label={"Status"}
                    value={status}
                    setState={setStatus}

                >
                    <SelectOption optionValue="Pending" optionText="Pending" hidden={false} disbled={false} />
                    <SelectOption optionValue="Active" optionText="Active" hidden={false} disbled={false} />
                    <SelectOption optionValue="Cancelled" optionText="Cancelled" hidden={false} disbled={false} />      
                    <SelectOption optionValue="Expired" optionText="Expired" hidden={false} disbled={false} />
                </SelectInput>
                <FormSubmitButton text="Update Subscription Request" />
            </Form>
        </div>
    );
}

export default UpdateSubscriptionRequest;
