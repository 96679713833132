import React, { useState } from 'react';
import Form from "../../../Partials/Forms/Form";
import FormSubmitButton from "../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";
import { SERVICES_PACKAGE_ITEMS_API } from "../../../../Utilities/APIs/ServicePackagesAPIs/ServicePackagesAPIs";
import ShortTextInput from "../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import Select from "react-select";
import InputFieldByAmountType from './InputFieldByAmountType';

function CreateServicePackageItem({ setShowCreateForm, triggerFetch }) {
  const [name, setName] = useState("");
  const [liteAmount, setLiteAmount] = useState("");
  const [essentialAmount, setEssentialAmount] = useState("");
  const [advancedAmount, setAdvancedAmount] = useState("");
  const [amountType, setAmountType] = useState("number");  // Default type
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const axiosInstance = useAxiosInstance();

  const typeOptions = [
    { label: "Number", value: "number" },
    { label: "Boolean", value: "boolean" },
    { label: "Text", value: "string" }
  ];

  async function handleSubmit(e) {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    const itemData = {
      name,
      liteAmount,
      essentialAmount,
      advancedAmount,
    };

    if (!name || liteAmount === undefined || essentialAmount === undefined || advancedAmount === undefined) {
      setError("Please fill in all fields.");
      setIsLoading(false);
      return;
    }

    try {
      const response = await axiosInstance.post(SERVICES_PACKAGE_ITEMS_API, itemData);
      console.log(response, "from create service package item");

      if (response) {
        triggerFetch();
        setShowCreateForm(false);
      }
    } catch (error) {
      console.error("Error creating service package item:", error);
      setError("An error occurred while creating the service package item. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="create_form">
      <Form onSubmit={handleSubmit}>
        <ShortTextInput
          label={"Name"}
          value={name}
          setState={setName}
          placeholder={"Enter Name"}
        />
        <Select
          options={typeOptions}
          onChange={(selected) => setAmountType(selected?.value || "number")}
          placeholder="Select Amount Type"
        />
        <InputFieldByAmountType
          type={amountType}
          value={liteAmount}
          onChange={(value) => setLiteAmount(value)}
          label={"Lite Amount"}
        />
        <InputFieldByAmountType
          type={amountType}
          value={essentialAmount}
          onChange={(value) => setEssentialAmount(value)}
          label={"Essential Amount"}
        />
        <InputFieldByAmountType
          type={amountType}
          value={advancedAmount}
          onChange={(value) => setAdvancedAmount(value)}
          label={"Advanced Amount"}
        />
        {error && <div style={{ color: "red", fontSize: "14px" }}>{error}</div>}
        <FormSubmitButton text={isLoading ? "Creating..." : "Create Service Package Item"} />
      </Form>
    </div>
  );
}

export default CreateServicePackageItem;












// import { useState } from "react";
// import Form from "../../../Partials/Forms/Form";
// import NumberInput from "../../../Partials/Forms/FormInputs/NumberInput/NumberInput";
// import FormSubmitButton from "../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
// import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";
// import { SERVICES_PACKAGE_ITEMS_API } from "../../../../Utilities/APIs/ServicePackagesAPIs/ServicePackagesAPIs";
// import ShortTextInput from "../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";

// function CreateServicePackageItem({ setShowCreateForm, triggerFetch, packageID }) {
//     const [name, setName] = useState("");
//     const [liteAmount, setLiteAmount] = useState("");
//     const [essentialAmount, setEssentialAmount] = useState("");
//     const [advancedAmount, setAdvancedAmount] = useState("");
//     const [error, setError] = useState("");
//     const [isLoading, setIsLoading] = useState(false);
//     const axiosInstance = useAxiosInstance();

//     async function handleSubmit(e) {
//         e.preventDefault();
//         setError("");
//         setIsLoading(true);

//         const itemData = {
//             name,
//             liteAmount,
//             essentialAmount,
//             advancedAmount,
//         };

//         if (!name || !liteAmount || !essentialAmount || !advancedAmount) {
//             setError("Please fill in all fields.");
//             setIsLoading(false);
//             return;
//         }

//         try {
//             const response = await axiosInstance.post(`${SERVICES_PACKAGE_ITEMS_API}`, itemData);
//             console.log(response, "from create service package item");

//             if (response) {
//                 triggerFetch();
//                 setShowCreateForm(false);
//             }
//         } catch (error) {
//             console.error("Error creating service package item:", error);
//             setError("An error occurred while creating the service package item. Please try again.");
//         } finally {
//             setIsLoading(false);
//         }
//     }

//     return (
//         <div className="create_form">
//             <Form onSubmit={handleSubmit}>
//                 <ShortTextInput
//                     label={"Name"}
//                     value={name}
//                     setState={setName}
//                     placeholder={"Enter Name"}
//                 />
//                 <NumberInput
//                     label={"Lite Amount"}
//                     value={liteAmount}
//                     setState={setLiteAmount}
//                     placeholder={"Enter Lite Amount"}
//                 />
//                 <NumberInput
//                     label={"Essential Amount"}
//                     value={essentialAmount}
//                     setState={setEssentialAmount}
//                     placeholder={"Enter Essential Amount"}
//                 />
//                 <NumberInput
//                     label={"Advanced Amount"}
//                     value={advancedAmount}
//                     setState={setAdvancedAmount}
//                     placeholder={"Enter Advanced Amount"}
//                 />
//                 {error && <div style={{ color: "red", fontSize: "14px" }}>{error}</div>}
//                 <FormSubmitButton text={isLoading ? "Creating..." : "Create Service Package Item"} />
//             </Form>
//         </div>
//     );
// }

// export default CreateServicePackageItem;
