function FilterSelect({ filter, setFilter, isQuoteRequest }) {

    return (

        <div style={{ display: "flex", alignItems: "center" ,    
            border: "1px solid white",
            borderRadius: "8px",
            padding: "2px"
            }}>

            <select className="filter_select" value={filter} onChange={(e) => setFilter(e.target.value)} >
                {
                    isQuoteRequest ?
                        <>
                            <option value={"subscription"}>Subscription</option>
                            <option value={"quote"}>Quote</option>
                        </>
                        :
                        <>
                            <option value={"active"}>Active</option>
                            <option value={"archive"}>Archive</option>
                        </>
                }

            </select>
        </div>

    )
}

export default FilterSelect