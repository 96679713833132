
import { RiGroupLine, RiHome2Line, } from "react-icons/ri"
import { MdContentCopy } from "react-icons/md";
import { SidebarItem } from "../Layout/SideAndNavBar/Sidebar/SidebarItem/SidebarItem"
import { MdOutlineCleaningServices } from "react-icons/md";
import { FaNetworkWired, FaBuildingUser } from "react-icons/fa6";
import { FaUsers } from "react-icons/fa";
import { FaMoneyCheckAlt } from 'react-icons/fa';
import { FaCrown } from 'react-icons/fa';



function NavItems() {
  return (
    <>
      <SidebarItem title="Dashboard" children={<RiHome2Line />} />
      <SidebarItem title='WebsiteContent' children={<MdContentCopy />} />
      <SidebarItem title='Users' children={<RiGroupLine />} />
      <SidebarItem title='Our Services' children={<MdOutlineCleaningServices />} />
      <SidebarItem title='Our Works' children={<FaNetworkWired />} />
      {/* <SidebarItem title='Clients' children={<FaBuildingUser />} /> */}
      <SidebarItem title='Clients' children={<FaBuildingUser />} />
      <SidebarItem title='Service Packages' children={<FaBuildingUser />} />
      <SidebarItem title='Newsletter' children={<FaBuildingUser />} />

      {/* <SidebarItem title='Packages' children={<FaMoneyCheckAlt />} />
      <SidebarItem title='Package Items' children={<FaCrown  />} />
      <SidebarItem title='Subscription Requests' children={<FaCrown  />} /> */}
      <SidebarItem title='team' children={<FaUsers />} />
      {/* 
      {(bizDev || projectManagement) && <SidebarItem title='Biz Dev' children={<RiAliensLine />} />}
      {execution && <SidebarItem title='Execution' children={<RiSwordLine />} />}
      {relationship && <SidebarItem title='Relationship' children={<RiHeart3Line />} />}
      {acquisition && <SidebarItem title='Acquisition' children={<RiGasStationLine />} />}
      {rnd && <SidebarItem title='RnD' children={<RiFlaskLine />} />}
      {marketing && <SidebarItem title='Marketing' children={<RiStarLine />} />}
      {hr && <SidebarItem title='HR' children={<RiEmotionLine />} />}
      {finance && <SidebarItem title='Finance' children={<RiCoinLine />} />}
      {officeManagement && <SidebarItem title='Office Management' children={<RiHomeOfficeLine />} />} */}
    </>
  );
}

export default NavItems;
