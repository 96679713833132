export const WEBSITE_CONTENTS_API = "websiteContents/";
export const EMPLOYEES_API = "manageEmployees/";
export const CUSTOMERS_API = "customers/manageCustomers/";

// export const HERO_SLIDERS_API = WEBSITE_CONTENTS_API + "heroSliders/";
// export const SOCIAL_LINKS_API = WEBSITE_CONTENTS_API + "socialLinks/";
// export const TESTIMONIALS_API = WEBSITE_CONTENTS_API + "testimonials/";
// export const VAT_API = ECOMMERCE_SETTINGS_API + "vats/";
// export const PROMO_CODE_API = ECOMMERCE_SETTINGS_API + "promoCodes/";
// export const DISCOUNT_API = ECOMMERCE_SETTINGS_API + "discounts/";
// export const DISCOUNT_FEE_API = ECOMMERCE_SETTINGS_API + "deliveryFees/";
export const SERVICES_EMPLOYEE_API =  "services";


export const ALL_CUSTOMERS_API = "getAllCustomers/";
