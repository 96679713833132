import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";
import { Image } from "../../../Partials/Elements/Image/Image";
import FilterSelect from "../../../Partials/Forms/FilterSelect/FilterSelect";

function ViewTeam({ targetID, api }) {
  const axiosInstance = useAxiosInstance();

  const { info: team } = useGetInfo({
    axiosInstance: axiosInstance,
    api: api + "/" + targetID,
  });

  return (
    <div className="crud_view_content">
      {team && (
        <>
          <h1>Image</h1>
          <Image imgLink={team.image} imgAlt={"info"} className={""} />
          <FilterSelect filter={team.isActive == true ? 'active' : 'archive'} setFilter={()=>{}}/>
        </>
      )}
    </div>
  );
}

export default ViewTeam;
