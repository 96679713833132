import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";
import { Image } from "../../../Partials/Elements/Image/Image";
import ShortTextInput from "../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import LongTextInput from "../../../Partials/Forms/FormInputs/LongTextInput/LongTextInput";
import FilterSelect from "../../../Partials/Forms/FilterSelect/FilterSelect";

function ViewService({ targetID, api }) {
  const axiosInstance = useAxiosInstance();

  const { info: service } = useGetInfo({
    axiosInstance: axiosInstance,
    api: api + "/" + targetID,
  });

  return (
    <div className="crud_view_content">
      {service && (
        <>
          <h1>Image</h1>
          <Image imgLink={service.image} imgAlt={"info"} className={""} />

          <h1>Title</h1>
          <p>{service?.title}</p>

          <h1>Description</h1>
          <p>{service?.description}</p>

          <h1>Is Active</h1>
          <p>{service?.isActive ? "Active" : "Archived"}</p>
        </>
      )}
    </div>
  );
}

export default ViewService;
