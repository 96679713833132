import { useEffect, useState } from "react"
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance"
import "./InviteEmployees.css"

function ResendInvite({employee,api, setShowResendSection, targetID}) {

  const [email, setEmail] = useState('')
  const axiosInstance = useAxiosInstance()

  useEffect(() => {

  
    async function fetchAndSetEmail() {
      const {data} = await axiosInstance.get( api+"getSingleInvite/" + targetID)
      setEmail(data.email)
    }

    fetchAndSetEmail()

  }, [targetID, axiosInstance])

  async function handleResend() {
      const itemData = {email}
      
      const response = await axiosInstance.post(api, itemData)

      if (response) {
        setEmail('')
        setShowResendSection(false);
    } 
  }

  return (
    <div className='modal_delete_content'>
      
      <p>Are you sure you want to resend invite to {email ? email : ''}?</p>

      <div className="modal_group_buttons">
        <button
          className="delete" onClick={() => {
            handleResend()
          }}
        >Resend</button>

        <button className="cancel" onClick={() => {
          setShowResendSection(false);
        }}>Cancel</button>
      </div>

    </div>
  )
}

export default ResendInvite