import axios from "axios";
import { useContext, useMemo } from "react";
import { AuthContext } from "../../Contexts/GlobalContexts/AuthContext";


export function useAxiosInstance() {

    const { employee, logout } = useContext(AuthContext)

    const axiosInstance = useMemo(() => axios.create({
        baseURL: process.env.REACT_APP_BACKEND_URL + process.env.REACT_APP_AXIOS_INSTANCE_ROUTE,
        headers: {
            'Authorization': 'Bearer ' + employee?.token
        }
    }), [employee])

    axiosInstance.interceptors.response.use(
        (res) => {
            return res;
        },
        (err) => {
            if (employee?.token && err?.response?.status === 401) {
                logout()
            }
            return Promise.reject(err);
        }
    );

    return axiosInstance;
}