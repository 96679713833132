import { useContext, useState } from "react"
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance"
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput"
import ImageInput from "../../../../Partials/Forms/FormInputs/ImageInput/ImageInput"
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton"
import Form from "../../../../Partials/Forms/Form"
import NumberInput from "../../../../Partials/Forms/FormInputs/NumberInput/NumberInput"
import LongTextInput from "../../../../Partials/Forms/FormInputs/LongTextInput/LongTextInput"

function CreateTestimonial({ api, setShowCreateForm, triggerFetch }) {

    const [name, setName] = useState('')
    const [designation, setDesignation] = useState('')
    const [description, setDescription] = useState('')
    const [image, setImage] = useState('')
    const [precedence, setPrecedence] = useState('')

    // const { triggerFetch } = useContext(LayoutContext)
    const axiosInstance = useAxiosInstance()


    async function handleSubmit(e) {
        e.preventDefault()

        const itemData = new FormData()
        itemData.append('name', name)
        itemData.append('designation', designation)
        itemData.append('description', description)
        itemData.append('image', image)
        itemData.append('precedence', precedence)

        const response = await axiosInstance.post(api, itemData)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }
    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>

                <ShortTextInput
                    label={`Name`}
                    value={name}
                    placeholder={`Enter Name`}
                    setState={setName}

                />
                <ShortTextInput
                    label={`Designation`}
                    value={designation}
                    placeholder={`Enter Designation`}
                    setState={setDesignation}

                />
                <LongTextInput
                    label={`Description`}
                    value={description}
                    placeholder={`Enter Description`}
                    setState={setDescription}

                />
                <ImageInput
                    fieldId='1'
                    state={image}
                    setState={setImage}
                    allowCreateImage
                >
                    Upload Image
                </ImageInput>

                <NumberInput
                    label={`Precedence`}
                    value={precedence}
                    placeholder={`Enter Precedence`}
                    setState={setPrecedence}

                />


                <FormSubmitButton text="create Testimonial" />
            </Form>
        </div>
    )
}

export default CreateTestimonial