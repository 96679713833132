import { useState } from "react";
import { IconThreeDots } from "antopolis-react-icons";
import { useGetInfo } from "antopolis-react-utils/hooks";

import { useUseOrientation } from "../../../../Utilities/Hooks/useUseOrientation";
import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";
import { CreateButton } from "../../../Partials/Layout/CRUD/CreateButton/CreateButton";
import { CRUDTable } from "../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../Partials/Layout/CRUD/CRUDth/CRUDth";

import {
  EditButton,
} from "../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { Modal } from "../../../Partials/Elements/Modal/Modal";
import CreateServicePackage from "./CreateServicePackage";
import UpdateServicePackage from "./UpdateServicePackage";
import { ShortTextCell } from "../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell";
import { SERVICES_PACKAGES_API } from "../../../../Utilities/APIs/ServicePackagesAPIs/ServicePackagesAPIs";

function ServicePackages() {

  const enums = Object.freeze(["monthly", "halfYearly", "yearly"]);

  const [showActionButton, setShowActionButton] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);

  const [targetID, setTargetID] = useState(null);

  const { portrait } = useUseOrientation();
  const axiosInstance = useAxiosInstance();

  // fetch packages

  const { info: packages } = useGetInfo({
    axiosInstance: axiosInstance,
    api: SERVICES_PACKAGES_API,
    toggleFetch,
  });

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }


  return (
    <div className="screen_wrapper">
      <div className="screen_header">
        <h1 className="screen_heading">
          Service packages ({packages?.length} in total)
        </h1>
        <CreateButton
          screenTopicSingular={"Service Package"}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable>
        <CRUDTableHeader>
          <CRUDth th={"Type"} />
          <CRUDth th={"Price"} />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {packages?.map((singlePackage) => (
            <tr key={singlePackage._id}>
              <ShortTextCell text={singlePackage.type} />
              <ShortTextCell text={singlePackage.price} />
              <td>
                <div className="action_buttons_wrapper">
                  <button
                    className="three_dots"
                    onClick={() => {
                      setShowActionButton(true);
                      setTargetID(singlePackage._id);
                    }}
                  >
                    <IconThreeDots />
                  </button>
                  {!portrait && (
                    <div className="action_buttons">
                    
                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={singlePackage._id}
                        setTargetID={setTargetID}
                      />
                    </div>
                  )}
                  {portrait &&
                    showActionButton &&
                    targetID === singlePackage._id && (
                      <div className="action_buttons">
                      
                        <EditButton
                          setShowUpdateForm={setShowUpdateForm}
                          targetID={singlePackage._id}
                          setTargetID={setTargetID}
                          setShowActionButton={setShowActionButton}
                        />

                      </div>
                    )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </CRUDTable>

      {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Create Service Package`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateServicePackage
            setShowCreateForm={setShowCreateForm}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}
      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Service Package`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateServicePackage
            setShowUpdateForm={setShowUpdateForm}
            targetID={targetID}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}


    </div>
  );
}

export default ServicePackages;
