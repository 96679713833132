import { useState } from "react"
import Form from "../../../Partials/Forms/Form";
import ImageInput from "../../../Partials/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";
import ShortTextInput from "../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import LongTextInput from "../../../Partials/Forms/FormInputs/LongTextInput/LongTextInput";


function CreateService({api, setShowCreateForm, triggerFetch }) {

    const [image, setImage] = useState('')
   const [title, setTitle]= useState("");
   const [description, setDescription] = useState("");

    // const { triggerFetch } = useContext(LayoutContext)
    const axiosInstance = useAxiosInstance()


    async function handleSubmit(e) {
        e.preventDefault()

        const itemData = new FormData()
        itemData.append('title', title)
        itemData.append('image', image)
        itemData.append('description', description)
        console.log(api, itemData);
        
        const response = await axiosInstance.post(api, itemData)
        console.log(response)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }
    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>

                <ImageInput
                    fieldId='1'
                    state={image}
                    setState={setImage}
                    allowCreateImage
                >
                    Upload Image
                </ImageInput>
                <ShortTextInput label={"title"} value={title} setState={setTitle}/>
                <LongTextInput label={"description"} value={description} setState={setDescription}/>
                <FormSubmitButton text="create service" />
            </Form>
        </div>
    )
}

export default CreateService