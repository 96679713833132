import { useContext, useEffect, useState } from "react"
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance"
// import { LayoutContext } from "../../../../Contexts/GlobalContexts/LayoutContext"
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput"
import ImageInput from "../../../../Partials/Forms/FormInputs/ImageInput/ImageInput"
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton"
import Form from "../../../../Partials/Forms/Form"
import { useGetInfo } from "antopolis-react-utils/hooks"
import NumberInput from "../../../../Partials/Forms/FormInputs/NumberInput/NumberInput"
import LongTextInput from "../../../../Partials/Forms/FormInputs/LongTextInput/LongTextInput"

function UpdateTestimonial({ api, setShowUpdateForm, targetID, triggerFetch }) {

    const [name, setName] = useState('')
    const [designation, setDesignation] = useState('')
    const [description, setDescription] = useState('')
    const [image, setImage] = useState('')
    const [precedence, setPrecedence] = useState('')

    // const { triggerFetch } = useContext(LayoutContext)
    const axiosInstance = useAxiosInstance()

    const { info: heroSlider } = useGetInfo({
        axiosInstance: axiosInstance,
        api: api + 'getSingleTestimonials/' + targetID,
    })

    useEffect(() => {
        if (heroSlider) {
            setName(heroSlider.name)
            setDesignation(heroSlider.designation)
            setDescription(heroSlider.description)
            setImage(heroSlider.image)
            setPrecedence(heroSlider.precedence)
        }
    }, [heroSlider])

    async function handleSubmit(e) {
        e.preventDefault()

        const itemData = new FormData()
        itemData.append('name', name)
        itemData.append('designation', designation)
        itemData.append('description', description)
        itemData.append('image', image)
        itemData.append('precedence', precedence)

        const response = await axiosInstance.patch(api + 'getSingleTestimonials/' + targetID, itemData)
        if (response) {
            triggerFetch()
            setShowUpdateForm(false)
        }
    }
    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>

                <ShortTextInput
                    label={`Name`}
                    value={name}
                    placeholder={`Enter Name`}
                    setState={setName}

                />
                <ShortTextInput
                    label={`Designation`}
                    value={designation}
                    placeholder={`Enter Designation`}
                    setState={setDesignation}

                />
                <LongTextInput
                    label={`Description`}
                    value={description}
                    placeholder={`Enter Description`}
                    setState={setDescription}

                />
                <ImageInput
                    fieldId='1'
                    state={image}
                    setState={setImage}
                    allowCreateImage
                >
                    Upload Image
                </ImageInput>

                <NumberInput
                    label={`Precedence`}
                    value={precedence}
                    placeholder={`Enter Precedence`}
                    setState={setPrecedence}

                />

                <FormSubmitButton text="Update Testimonial" />
            </Form>
        </div>
    )
}

export default UpdateTestimonial