import FormTop from "../../../Partials/Forms/FormTop/FormTop"
import RegisterForm from "./RegisterForm/RegisterForm"



function Register() {
    return (
        <div className="form_wrapper">
            <FormTop title='Register to Purple bot' />
            <RegisterForm/>
        </div>
    )
}

export default Register
