import { FaMoneyCheckAlt } from "react-icons/fa"
import { FaCrown } from "react-icons/fa6"
import { RiUserShared2Line } from "react-icons/ri"
import { NavCardList } from "../../../Partials/Layout/NavCardList/NavCardList"
import { ScreenHolder } from "../../../Partials/Layout/ScreenHolder/ScreenHolder"
import { NavCard } from "../../../Partials/Layout/NavCardList/NavCard/NavCard"


function ServicePackagesDashboard() {
    return (
        <ScreenHolder>
            <NavCardList numOfCards={'two'} >
                <NavCard cardName={'Service Packages'} navCardLink='/main/packages' ><FaMoneyCheckAlt /></NavCard>
                <NavCard cardName={'Service Package Items'} navCardLink='/main/packageItems' ><FaCrown /></NavCard>
                <NavCard cardName={'Subscription & Quote Requests'} navCardLink='/main/subscriptionRequests' ><RiUserShared2Line /></NavCard>
            </NavCardList>
        </ScreenHolder>
    )
}

export default ServicePackagesDashboard
