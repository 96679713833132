import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import { Image } from "../../../../Partials/Elements/Image/Image";

function ViewTestimonial({
    targetID,
    api
}) {

    const axiosInstance = useAxiosInstance();

    const { info: testimonial } = useGetInfo({
        axiosInstance: axiosInstance,
        api: api + 'getSingleTestimonials/' + targetID,
    })


    return (

        <div className="crud_view_content">

            {testimonial &&
                <>
                    <h1>Name</h1>
                    <p>{testimonial.name}</p>
                    <h1>Designation</h1>
                    <p>{testimonial.designation}</p>
                    <h1>Description</h1>
                    <p>{testimonial.description}</p>

                    <h1>Image</h1>
                    <Image
                        imgLink={testimonial.image}
                        imgAlt={'info'}
                        className={''}
                    />


                </>
            }
        </div>
    )
}

export default ViewTestimonial