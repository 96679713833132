import { useState } from "react"
import Form from "../../../Partials/Forms/Form";
import ImageInput from "../../../Partials/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";


function CreateClient({api, setShowCreateForm, triggerFetch }) {

    const [image, setImage] = useState('')

    // const { triggerFetch } = useContext(LayoutContext)
    const axiosInstance = useAxiosInstance()


    async function handleSubmit(e) {
        e.preventDefault()

        const itemData = new FormData()
        itemData.append('image', image)
        console.log(api, itemData);
        
        const response = await axiosInstance.post(api, itemData)
        console.log(response)
        if (response) {
            triggerFetch()
            setShowCreateForm(false)
        }
    }
    return (
        <div className='crate_form'>
            <Form onSubmit={handleSubmit}>

                <ImageInput
                    fieldId='1'
                    state={image}
                    setState={setImage}
                    allowCreateImage
                >
                    Upload Image
                </ImageInput>
                <FormSubmitButton text="create client" />
            </Form>
        </div>
    )
}

export default CreateClient