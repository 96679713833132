import Select from "react-select";
import { useState } from "react";
import Form from "../../../Partials/Forms/Form";
import FormSubmitButton from "../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";
import NumberInput from "../../../Partials/Forms/FormInputs/NumberInput/NumberInput";
import { SERVICES_PACKAGES_API } from "../../../../Utilities/APIs/ServicePackagesAPIs/ServicePackagesAPIs";

function CreateServicePackage({
  setShowCreateForm,
  triggerFetch,
}) {
  const [type, setType] = useState("");
  const [price, setPrice] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();

    // Reset error state before submission
    setError("");

    // Validation
    if (!type || !price) {
      setError("Please fill in all fields.");
      return;
    }

    const itemData = {
      type,
      price
    };

    setIsLoading(true); // Set loading to true before the request

    try {
      const response = await axiosInstance.post(SERVICES_PACKAGES_API, itemData);
      console.log(response, "from create service package");

      if (response) {
        triggerFetch();
        setShowCreateForm(false);
      }
    } catch (error) {
      console.error("Error creating service package:", error);
      setError("An error occurred while creating the service package. Please try again.");
    } finally {
      setIsLoading(false); // Reset loading state
      setShowCreateForm(false);
    }
  }

  const types = ["Lite", "Essential", "Advanced"];
  const formattedOptions =
    types?.map((option) => ({
      label: option,
      value: option,
    })) || [];

  return (
    <div className="crate_form">
      <Form onSubmit={handleSubmit}>

        <Select
          options={formattedOptions}
          onChange={(selected) => setType(selected?.value || "")}
          placeholder="Select an option"
        />
        
        <NumberInput
          label={"Enter Price"}
          value={price}
          setState={setPrice}
          placeholder={"Enter Price"}
        />

        {error && <div
          style={{ color: "red", fontSize: "14px" }}>
          {error}
        </div>}
        <FormSubmitButton text={isLoading ? "Creating..." : "Create Service Package"} />
      </Form>
    </div>
  );
}

export default CreateServicePackage;
