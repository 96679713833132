import { CLIENTS_EMPLOYEE_API } from "../../../Utilities/APIs/ClientsAPIs/ClientAPIs";
import { SERVICES_EMPLOYEE_API } from "../../../Utilities/APIs/OurServicesAPIs/OurServicesAPIs";
import { SERVICES_PACKAGES_API } from "../../../Utilities/APIs/ServicePackagesAPIs/ServicePackagesAPIs";
import {
  ALL_EMPLOYEE_API,
  CUSTOMERS_API,
  DISCOUNT_API,
  DISCOUNT_FEE_API,
  EMPLOYEES_API,
  HERO_SLIDERS_API,
  PROMO_CODE_API,
  SOCIAL_LINKS_API,
  TESTIMONIALS_API,
  VAT_API,
} from "../../../Utilities/APIs/WebsiteContentsAPIs/WebsiteContentsAPIs";
import { TEAM_EMPLOYEE_API } from "../../../Utilities/APIs/TeamAPIs/TeamAPIs.js";
import Clients from "./Clients/Clients";
import Dashboard from "./Dashboard/Dashboard";
import OurServices from "./OurServices/OurServices";
import Team from "./Team/Team";
import AddressesFromCustomer from "./Users/Customers/AddressesFromCustomer/AddressesFromCustomer";
import Customers from "./Users/Customers/Customers";
import OrdersFromCustomer from "./Users/Customers/OrdersFromCustomer/OrdersFromCustomer";
import WishlistFromCustomer from "./Users/Customers/WishlistFromCustomer/WishlistFromCustomer";
import EmployeeScreen from "./Users/EmployeeScreen/EmployeeScreen";
import Employees from "./Users/EmployeeScreen/Employees/Employees";
import InviteEmployees from "./Users/EmployeeScreen/InviteEmployees/InviteEmployees";
import Users from "./Users/Users";
import HeroSlider from "./WebsiteContent/HeroSlider/HeroSlider";
import SocialLinks from "./WebsiteContent/SocialLinks/SocialLinks";
import Testimonial from "./WebsiteContent/Testimonial/Testimonial";
import WebsiteContent from "./WebsiteContent/WebsiteContent";
import OurWorks from "./ourWorks/OurWorks";
import { OURWORKS_EMPLOYEE_API } from "../../../Utilities/APIs/OurWorksAPIs/OurWorksAPIs.js";
import ServicePackages from "./servicePackages/ServicePackages.js";
import { PACKAGES_ITEM_EMPLOYEE_API } from "../../../Utilities/APIs/ServicePackagesItemAPIs/ServicePackagesItemAPIs.js";
import ServicePackageItems from "./ServicePackagesItems/ServicePackageItems.js";
import SubscriptionRequest from "./SubscriptionRequest/SubscriptionRequest.js";
import ServicePackagesDashboard from "./ServicePackagesDashboard/ServicePackagesDashboard.js";
import WorkAssets from "./ourWorks/WorkAssets/WorkAssets.js";
import NewsletterSubscribe from "./NewsletterSubscribe/NewsletterSubscribe.js";

const MainRoutes = [
  {
    path: "dashboard",
    element: <Dashboard />,
  },
  {
    path: "websiteContent",
    element: <WebsiteContent />,
  },
  {
    path: "heroSliders",
    element: <HeroSlider api={HERO_SLIDERS_API} />,
  },
  {
    path: "testimonials",
    element: <Testimonial api={TESTIMONIALS_API} />,
  },
  {
    path: "socialLinks",
    element: <SocialLinks api={SOCIAL_LINKS_API} />,
  },
  {
    path: "Users",
    element: <Users />,
  },
  //
  //
  {
    path: "ourServices",
    element: <OurServices api={SERVICES_EMPLOYEE_API} />,
  },
  {
    path: 'servicePackages',
    element: <ServicePackagesDashboard />,
  },
  {
    path: "packages",
    element: <ServicePackages />,
  },
  {
    path: "packageItems",
    element: <ServicePackageItems />,
  },

  {
    path: "subscriptionRequests",
    element: <SubscriptionRequest />,
  },
  {
    path: "newsletter",
    element: <NewsletterSubscribe />,
  },

  {
    path: "clients",
    element: <Clients api={CLIENTS_EMPLOYEE_API} />,
  },
  {
    path: "ourWorks",
    element: <OurWorks api={OURWORKS_EMPLOYEE_API} />,
  },
  {
    path: "workAssets/:workID",
    element: <WorkAssets />,
  },
  {
    path: "team",
    element: <Team api={TEAM_EMPLOYEE_API} />,
  },
  //
  //
  {
    path: "employeeScreen",
    element: <EmployeeScreen />,
  },
  {
    path: "employees",
    element: <Employees api={ALL_EMPLOYEE_API} />,
  },
  {
    path: "employeeInvites",
    element: <InviteEmployees api={EMPLOYEES_API} />,
  },
  {
    path: "customers",
    element: <Customers api={CUSTOMERS_API} />,
  },
  {
    path: "wishlist_from_customer/:customerID",
    element: <WishlistFromCustomer api={CUSTOMERS_API} />,
  },
  {
    path: "orders_from_customer/:customerID",
    element: <OrdersFromCustomer api={CUSTOMERS_API} />,
  },
  {
    path: "addresses_from_customer/:customerID",
    element: <AddressesFromCustomer api={CUSTOMERS_API} />,
  },
];

export default MainRoutes;
