  // Helper function to determine the type of a value
  const determineType = (value) => {
    if (typeof value === 'boolean') {
      return 'boolean';
    } else if (typeof value === 'number') {
      return 'number';
    } else if (typeof value === 'string') {
      return 'string';
    } else {
      return 'unknown'; // Fallback for unexpected types
    }
  };
  
  
 export   const formatValue = (value) => {
      const type = determineType(value);
      switch (type) {
        case 'boolean':
          return value ? 'Yes' : 'No'; // Display 'Yes' or 'No' for boolean values
        case 'number':
          return value.toString(); // Convert number to string
        case 'string':
          return value; // Display the string as-is
        default:
          return 'N/A'; // Default case if type is unknown
      }
    };
  