import Form from "../../../Partials/Forms/Form";
import { useEffect, useState } from "react";
import FormSubmitButton from "../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";
import SwitchInput from "../../../Partials/Forms/FormInputs/SwitchInput/SwitchInput";
import { SERVICES_PACKAGE_ITEMS_API } from "../../../../Utilities/APIs/ServicePackagesAPIs/ServicePackagesAPIs";
import { useGetInfo } from "antopolis-react-utils/hooks";
import ShortTextInput from "../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import Select from "react-select";
import InputFieldByAmountType from './InputFieldByAmountType';  // Import your InputFieldByAmountType component

function UpdateServicePackageItem({ setShowUpdateForm, targetID, triggerFetch }) {
  const [name, setName] = useState("");
  const [liteAmount, setLiteAmount] = useState("");
  const [essentialAmount, setEssentialAmount] = useState("");
  const [advancedAmount, setAdvancedAmount] = useState("");
  const [amountType, setAmountType] = useState("number");  // Default type
  const [isActive, setIsActive] = useState(true);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const axiosInstance = useAxiosInstance();

  const { info: item } = useGetInfo({
    axiosInstance: axiosInstance,
    api: `${SERVICES_PACKAGE_ITEMS_API}/getSingleServicePackageItem/${targetID}`,
  });

  useEffect(() => {
    if (item) {
      setName(item.name);
      setLiteAmount(item.liteAmount);
      setEssentialAmount(item.essentialAmount);
      setAdvancedAmount(item.advancedAmount);
      setIsActive(item.isActive);

      // Infer amount type based on liteAmount value
      const inferAmountType = (value) => {
        if (typeof value === 'number') return 'number';
        if (typeof value === 'boolean') return 'boolean';
        if (typeof value === 'string') return 'string';
        return 'number';  // Default to 'number' if undetermined
      };

      setAmountType(inferAmountType(item.liteAmount));
    }
  }, [item]);

  async function handleSubmit(e) {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    const itemData = {
      name,
      liteAmount,
      essentialAmount,
      advancedAmount,
      isActive,
      amountType,  // Include amountType in the data being sent
    };

    try {
      const response = await axiosInstance.patch(`${SERVICES_PACKAGE_ITEMS_API}/${targetID}`, itemData);
      console.log(response, 'res');

      if (response) {
        triggerFetch();
        setShowUpdateForm(false);
      }
    } catch (error) {
      console.error("Error updating service package item:", error);
      setError("An error occurred while updating the service package item. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }

  const typeOptions = [
    { label: "Number", value: "number" },
    { label: "Boolean", value: "boolean" },
    { label: "String", value: "string" }
  ];

  return (
    <div className="create_form">
      <Form onSubmit={handleSubmit}>
        <ShortTextInput
          label={"Name"}
          value={name}
          setState={setName}
          placeholder={"Enter Name"}
        />
        <Select
          options={typeOptions}
          value={typeOptions.find(option => option.value === amountType)}
          onChange={(selected) => setAmountType(selected?.value || "number")}
          placeholder="Select Amount Type"
        />
        <InputFieldByAmountType
          type={amountType}
          value={liteAmount}
          onChange={(value) => setLiteAmount(value)}
          label={"Lite Package Amount"}
        />
        <InputFieldByAmountType
          type={amountType}
          value={essentialAmount}
          onChange={(value) => setEssentialAmount(value)}
          label={"Essential Package Amount"}
        />
        <InputFieldByAmountType
          type={amountType}
          value={advancedAmount}
          onChange={(value) => setAdvancedAmount(value)}
          label={"Advanced Package Amount"}
        />
        <SwitchInput
          label={'Is Active'}
          toggleSwitch={() => setIsActive(prevState => !prevState)}
          checked={isActive}
        />
        {error && <div style={{ color: "red", fontSize: "14px" }}>{error}</div>}
        <FormSubmitButton text={isLoading ? "Updating..." : "Update Service Package Item"} />
      </Form>
    </div>
  );
}

export default UpdateServicePackageItem;








// import Form from "../../../Partials/Forms/Form";
// import { useEffect, useState } from "react";
// import NumberInput from "../../../Partials/Forms/FormInputs/NumberInput/NumberInput";
// import FormSubmitButton from "../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
// import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";
// import SwitchInput from "../../../Partials/Forms/FormInputs/SwitchInput/SwitchInput";
// import { SERVICES_PACKAGE_ITEMS_API } from "../../../../Utilities/APIs/ServicePackagesAPIs/ServicePackagesAPIs";
// import { useGetInfo } from "antopolis-react-utils/hooks";
// import ShortTextInput from "../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";

// function UpdateServicePackageItem({ setShowUpdateForm, targetID, triggerFetch,}) {
//   const [name, setName] = useState("");
//   const [liteAmount, setLiteAmount] = useState("");
//   const [essentialAmount, setEssentialAmount] = useState("");
//   const [advancedAmount, setAdvancedAmount] = useState("");
//   const [isActive, setIsActive] = useState(true);
//   const [error, setError] = useState("");
//   const [isLoading, setIsLoading] = useState(false);

//   const axiosInstance = useAxiosInstance();

//   const { info: item } = useGetInfo({
//     axiosInstance: axiosInstance,
//     api: `${SERVICES_PACKAGE_ITEMS_API}/getSingleServicePackageItem/${targetID}`,
//   });

//   useEffect(() => {
//     if (item) {
//       setName(item.name);
//       setLiteAmount(item.liteAmount);
//       setEssentialAmount(item.essentialAmount);
//       setAdvancedAmount(item.advancedAmount);
//       setIsActive(item.isActive);
//     }
//   }, [item]);

//   async function handleSubmit(e) {
//     e.preventDefault();
//     setError("");
//     setIsLoading(true);

//     const itemData = {
//       name,
//       liteAmount,
//       essentialAmount,
//       advancedAmount,
//       isActive,
//     };

//     try {
//       const response = await axiosInstance.patch(`${SERVICES_PACKAGE_ITEMS_API}/${targetID}`, itemData);
//       console.log(response, 'res');

//       if (response) {
//         triggerFetch();
//         setShowUpdateForm(false);
//       }
//     } catch (error) {
//       console.error("Error updating service package item:", error);
//       setError("An error occurred while updating the service package item. Please try again.");
//     } finally {
//       setIsLoading(false);
//     }
//   }

//   return (
//     <div className="create_form">
//       <Form onSubmit={handleSubmit}>
//         <ShortTextInput
//           label={"Name"}
//           value={name}
//           setState={setName}
//           placeholder={"Enter Name"}
//         />
//         <NumberInput
//           label={"Lite Amount"}
//           value={liteAmount}
//           setState={setLiteAmount}
//           placeholder={"Enter Lite Amount"}
//         />
//         <NumberInput
//           label={"Essential Amount"}
//           value={essentialAmount}
//           setState={setEssentialAmount}
//           placeholder={"Enter Essential Amount"}
//         />
//         <NumberInput
//           label={"Advanced Amount"}
//           value={advancedAmount}
//           setState={setAdvancedAmount}
//           placeholder={"Enter Advanced Amount"}
//         />
//         <SwitchInput
//           label={'Is Active'}
//           toggleSwitch={() => setIsActive(prevState => !prevState)}
//           checked={isActive}
//         />
//         {error && <div style={{ color: "red", fontSize: "14px" }}>{error}</div>}
//         <FormSubmitButton text={isLoading ? "Updating..." : "Update Service Package Item"} />
//       </Form>
//     </div>
//   );
// }

// export default UpdateServicePackageItem;
