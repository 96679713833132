import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";
import { CRUDTable } from "../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../Partials/Layout/CRUD/CRUDth/CRUDth";


function NewsletterSubscribe() {

    const axiosInstance = useAxiosInstance();

    // Fetch email subscriptions
    const { info: emailSubscriptions } = useGetInfo({
        axiosInstance: axiosInstance,
        api: 'newsLetters',
    });

    return (
        <div className="screen_wrapper">
            <div className="screen_header">
                <h1 className="screen_heading">
                    Newsletter Subscriptions ({emailSubscriptions?.length} in total)
                </h1>
            </div>

            <CRUDTable>
                <CRUDTableHeader>
                    <CRUDth th="Email" />
                    <CRUDth th="Subscribed At" />
                </CRUDTableHeader>
                <tbody>
                    {emailSubscriptions?.map((subscription) => (
                        <tr key={subscription._id}>
                            <td>{subscription?.email}</td>
                            <td>{subscription?.createdAt.slice(0,10)}</td>
                          
                        </tr>
                    ))}
                </tbody>
            </CRUDTable>

        </div>
    );
}

export default NewsletterSubscribe;
