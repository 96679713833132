import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";
import { SUBSCRIPTION_REQUESTS_API } from "../../../../Utilities/APIs/SubscriptionAPIs/SubscriptionAPIs";

function ViewSubscriptionRequest({ targetID, filter }) {
    const axiosInstance = useAxiosInstance();
    const { info: subscriptionRequest } = useGetInfo({
        axiosInstance: axiosInstance,
        api: SUBSCRIPTION_REQUESTS_API + targetID,
    });

    return (
        <div className="crud_view_content">

            <>
                <h1>Service</h1>
                <p>
                    <ul>
                        {subscriptionRequest?.services?.map((service, index) => (
                            <li key={index}>{index + 1}. {service.title}.</li>
                        ))}
                    </ul>
                </p>


                {filter === "subscription" && (
                    <>
                        <h1>Package</h1>
                        <p>{subscriptionRequest?.package?.title}</p>
                    </>
                )}

                <h1>Name</h1>
                <p>{subscriptionRequest?.name}</p>

                <h1>Email</h1>
                <p>{subscriptionRequest?.email}</p>

                <h1>Phone</h1>
                <p>{subscriptionRequest?.phone}</p>

                <h1>Status</h1>
                <p>{subscriptionRequest?.status}</p>

                {subscriptionRequest?.socialLink && (
                    <>
                        <h1>Social Link</h1>
                        <p>{subscriptionRequest.socialLink}</p>
                    </>
                )}

            </>

        </div>
    );
}

export default ViewSubscriptionRequest;
