import { Link } from 'react-router-dom'
import logoImg from '../../../logo.svg'
import wideLogoImg from '../../../logo-wide.svg'
import { Image } from '../../../../../Elements/Image/Image'

function SidebarLogo({ handleClick, expandSidebar }) {
    return (
        <Link href="/" onClick={handleClick}>
            {
                !expandSidebar
                ?
                // <Image
                //     imgLink={logoImg}
                //     imgAlt={'brand'}
                //     className={'logo'}
                //     assetOrWeb
                // />
                <p className="logo"> PP</p>
                :
                // <Image
                //     imgLink={wideLogoImg}
                //     imgAlt={'brand'}
                //     className={'logo_wide'}
                //     assetOrWeb
                // />
                <p className="logo"> Purple bot</p>
            }
        </Link>
    )
}

export default SidebarLogo