import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";
import { Image } from "../../../Partials/Elements/Image/Image";
import FilterSelect from "../../../Partials/Forms/FilterSelect/FilterSelect";

function ViewClient({ targetID, api }) {
  const axiosInstance = useAxiosInstance();

  const { info: client } = useGetInfo({
    axiosInstance: axiosInstance,
    api: api + "/" + targetID,
  });

  return (
    <div className="crud_view_content">
      {client && (
        <>
          <h1>Image</h1>
          <Image imgLink={client.image} imgAlt={"info"} className={""} />
          <FilterSelect filter={client.isActive == true ? 'active' : 'archive'} setFilter={()=>{}}/>
        </>
      )}
    </div>
  )
}

export default ViewClient;
