import { useGetInfo } from "antopolis-react-utils/hooks";
import { OURWORKS_ASSETS_API } from "../../../../../Utilities/APIs/OurWorksAPIs/OurWorksAPIs";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import { Image } from "../../../../Partials/Elements/Image/Image";

function ViewWorkAsset({ targetID }) {
  const axiosInstance = useAxiosInstance();

  const { info: asset } = useGetInfo({
    axiosInstance: axiosInstance,
    api: `${OURWORKS_ASSETS_API}/${targetID}`,
  });

  return (
    <div className="crud_view_content">
      {asset && (
        <>
          <h1>Title</h1>
          <p>{asset.title}</p>

          <h1>Description</h1>
          <p>{asset.description}</p>

          <h1>Image</h1>
          <Image imgLink={asset.image} imgAlt={asset.title} />

          <h1>Status</h1>
          <p>{asset.isActive ? "Active" : "Inactive"}</p>
        </>
      )}
    </div>
  );
}

export default ViewWorkAsset;
