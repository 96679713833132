import { useEffect, useState } from "react";
import Select from "react-select";
import Form from "../../../Partials/Forms/Form";
import ImageInput from "../../../Partials/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";
import ShortTextInput from "../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import CustomEditor from "../../../Partials/Elements/CustomEditor/CustomEditor";
import { useGetInfo } from "antopolis-react-utils/hooks";
import SwitchInput from "../../../Partials/Forms/FormInputs/SwitchInput/SwitchInput";
import LongTextInput from "../../../Partials/Forms/FormInputs/LongTextInput/LongTextInput";

function UpdateWork({ api, setShowUpdateForm, targetID, triggerFetch, types }) {
  const [image, setImage] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [thumbnailImage, setThumbnailImage] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [type, setType] = useState("");
  const [cardImageTitle, setCardImageTitle] = useState("");
  const [cardImageDescription, setCardImageDescription] = useState("");
  const [title, setTitle] = useState("");
  const [client, setClient] = useState("");
  const [category, setCategory] = useState("");
  const [thumbnailLink, setThumbnailLink] = useState("");
  const [writeUpsHeadingText, setWriteUpsHeadingText] = useState("");
  const [writeUpsSubText, setWriteUpsSubText] = useState("");
  const [writeUps, setWriteUps] = useState("");
  const [error, setError] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const axiosInstance = useAxiosInstance();

  const { info: work } = useGetInfo({
    axiosInstance: axiosInstance,
    api: api + "/" + targetID,
  });

  useEffect(() => {
    if (work) {
      setImage(work.image);
      setBannerImage(work.bannerImage);
      setThumbnailImage(work.thumbnailImage);
      setIsActive(work.isActive);
      setType(work.type);
      setCardImageTitle(work.cardImageTitle);
      setCardImageDescription(work.cardImageDescription);
      setTitle(work.title);
      setClient(work.client);
      setCategory(work.category);
      setThumbnailLink(work.thumbnailLink);
      setWriteUpsHeadingText(work.writeUpsHeadingText);
      setWriteUpsSubText(work.writeUpsSubText);
      setWriteUps(work.writeUps);
    }
  }, [work]);

  const formattedOptions =
    types?.map((option) => ({
      label: option,
      value: option,
    })) || [];

  async function handleSubmit(e) {
    e.preventDefault();

    if (
      !title ||
      !client ||
      !category ||
      !thumbnailLink ||
      !writeUpsHeadingText ||
      !writeUpsSubText ||
      !writeUps ||
      !type ||
      !image ||
      !bannerImage ||
      !thumbnailImage ||
      !cardImageTitle ||
      !cardImageDescription
    ) {
      setError("Please fill all the fields");
      return;
    }
    setIsDisabled(true);
    const itemData = new FormData();
    itemData.append("isActive", isActive);
    itemData.append("type", type);
    itemData.append("image", image);
    itemData.append("bannerImage", bannerImage);
    itemData.append("thumbnailImage", thumbnailImage);
    itemData.append("cardImageTitle", cardImageTitle);
    itemData.append("cardImageDescription", cardImageDescription);
    itemData.append("title", title);
    itemData.append("client", client);
    itemData.append("category", category);
    itemData.append("thumbnailLink", thumbnailLink);
    itemData.append("writeUpsHeadingText", writeUpsHeadingText);
    itemData.append("writeUpsSubText", writeUpsSubText);
    itemData.append("writeUps", writeUps);

    try {
      const response = await axiosInstance.patch(api + "/" + targetID, itemData);
      if (response) {
        triggerFetch();
        setShowUpdateForm(false);
        setIsDisabled(false);
      }
    } catch (error) {
      console.error("Error while updating data:", error);
      setError(
        error.response?.data?.message || "An error occurred. Please try again."
      );
      setIsDisabled(false);
    }
  }

  return (
    <div className="update_form">
      <Form onSubmit={handleSubmit}>
        <Select
          options={formattedOptions}
          value={formattedOptions.find((option) => option.value === type)}
          onChange={(selected) => setType(selected?.value)}
          placeholder="Select Work Type"
        />

        <ImageInput
          fieldId="1"
          state={image}
          setState={setImage}
          allowUpdateImage
        >
          Update Card Image
        </ImageInput>

        <ShortTextInput
          label={"Card Image Title"}
          value={cardImageTitle}
          setState={setCardImageTitle}
          placeholder={"Enter Card Image Title"}
        />

        <LongTextInput
          label={"Card Image Description"}
          value={cardImageDescription}
          setState={setCardImageDescription}
          placeholder={"Enter Card Image Description"}
        />

        <ImageInput
          fieldId="2"
          state={bannerImage}
          setState={setBannerImage}
          allowUpdateImage
        >
          Update Banner Image
        </ImageInput>

        <ShortTextInput
          label={"Title"}
          value={title}
          setState={setTitle}
          placeholder={"Enter Title"}
        />

        <ShortTextInput
          label={"Client"}
          value={client}
          setState={setClient}
          placeholder={"Enter Client"}
        />

        <ShortTextInput
          label={"Category"}
          value={category}
          setState={setCategory}
          placeholder={"Enter Category"}
        />

        <ImageInput
          fieldId="3"
          state={thumbnailImage}
          setState={setThumbnailImage}
          allowUpdateImage
        >
          Update Thumbnail Image
        </ImageInput>

        <ShortTextInput
          label={"Thumbnail Link"}
          value={thumbnailLink}
          setState={setThumbnailLink}
          placeholder={"Enter Thumbnail Link"}
        />

        <ShortTextInput
          label={"WriteUps Heading Text"}
          value={writeUpsHeadingText}
          setState={setWriteUpsHeadingText}
          placeholder={"Enter WriteUps Heading Text"}
        />

        <ShortTextInput
          label={"WriteUps Sub Text"}
          value={writeUpsSubText}
          setState={setWriteUpsSubText}
          placeholder={"Enter WriteUps Sub Text"}
        />

        <SwitchInput
          label={"Is Active"}
          toggleSwitch={() => setIsActive(!isActive)}
          checked={isActive}
        />

        <CustomEditor label="WriteUps" data={writeUps} setState={setWriteUps} />

        <FormSubmitButton text="Update Work" disabled={isDisabled} />
      </Form>
    </div>
  );
}

export default UpdateWork;















// import { useContext, useEffect, useState } from "react";
// import { useGetInfo } from "antopolis-react-utils/hooks";
// import Form from "../../../Partials/Forms/Form";
// import ImageInput from "../../../Partials/Forms/FormInputs/ImageInput/ImageInput";
// import FormSubmitButton from "../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
// import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";
// import FilterSelect from "../../../Partials/Forms/FilterSelect/FilterSelect";
// function UpdateWork({ api, setShowUpdateForm, targetID, triggerFetch, types }) {
//   const [image, setImage] = useState("");
//   const [isActive, setIsActive] = useState("active");
//   const [type, setType] = useState("");
//   // const { triggerFetch } = useContext(LayoutContext)
//   const axiosInstance = useAxiosInstance();

//   const { info: work } = useGetInfo({
//     axiosInstance: axiosInstance,
//     api: api + "/" + targetID,
//   });

//   useEffect(() => {
//     if (work) {
//       setImage(work.image);
//       setIsActive(work.isActive == true ? "active" : "archive");
//       setType(work.type);
//     }
//   }, [work]);

//   async function handleSubmit(e) {
//     e.preventDefault();

//     const itemData = new FormData();
//     itemData.append("isActive", isActive);
//     itemData.append("image", image);
//     itemData.append("type", type);

//     const response = await axiosInstance.patch(api + "/" + targetID, itemData);
//     console.log(response, "res");
//     if (response) {
//       triggerFetch();
//       setShowUpdateForm(false);
//     }
//   }
//   //
//   console.log(type, "type");
//   //
//   const formattedOptions =
//     types?.map((option) => ({
//       label: option,
//       value: option,
//     })) || [];
//   return (
//     <div className="crate_form">
//       <Form onSubmit={handleSubmit}>
//         <ImageInput
//           fieldId="1"
//           state={image}
//           setState={setImage}
//           allowUpdateImage
//         >
//           Upload Image
//         </ImageInput>
//         <FilterSelect filter={isActive} setFilter={setIsActive} />
//         {/* <Select
//         label={type}
//         options={formattedOptions}
//         onChange={(selected) => setType(selected?.value )}
//         placeholder="Select an option"
//       /> */}
//         <select value={type} onChange={(selected) => setType(selected.target.value)}>
//           {types.map((eachType, idx) => (
//             <option key={idx}>{eachType}</option>
//           ))}
//         </select>
//         <FormSubmitButton text="Update work" />
//       </Form>
//     </div>
//   );
// }

// export default UpdateWork;
