import { useState } from "react";
import Form from "../../../Partials/Forms/Form";
import ImageInput from "../../../Partials/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";
import Select from "react-select";
import ShortTextInput from "../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import CustomEditor from "../../../Partials/Elements/CustomEditor/CustomEditor";
import LongTextInput from "../../../Partials/Forms/FormInputs/LongTextInput/LongTextInput";

function CreateWork({ api, setShowCreateForm, triggerFetch, types }) {
  const [image, setImage] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [thumbnailImage, setThumbnailImage] = useState("");
  const [type, setType] = useState("");
  const [cardImageTitle, setCardImageTitle] = useState("");
  const [cardImageDescription, setCardImageDescription] = useState("");
  const [title, setTitle] = useState("");
  const [client, setClient] = useState("");
  const [category, setCategory] = useState("");
  const [thumbnailLink, setThumbnailLink] = useState("");
  const [writeUpsHeadingText, setWriteUpsHeadingText] = useState("");
  const [writeUpsSubText, setWriteUpsSubText] = useState("");
  const [writeUps, setWriteUps] = useState("");
  const [error, setError] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();


    if(!title || !client || !category || !thumbnailLink || !writeUpsHeadingText 
      || !writeUpsSubText || !writeUps || !type || !image || !bannerImage || !thumbnailImage 
      || !cardImageTitle || !cardImageDescription) {
      setError("Please fill all the fields")
      return
    }

    setIsDisabled(true)

    const itemData = new FormData();
    itemData.append("type", type);
    itemData.append("image", image);
    itemData.append("bannerImage", bannerImage);
    itemData.append("thumbnailImage", thumbnailImage);
    itemData.append("cardImageTitle", cardImageTitle);
    itemData.append("cardImageDescription", cardImageDescription);
    itemData.append("title", title);
    itemData.append("client", client);
    itemData.append("category", category);
    itemData.append("thumbnailLink", thumbnailLink);
    itemData.append("writeUpsHeadingText", writeUpsHeadingText);
    itemData.append("writeUpsSubText", writeUpsSubText);
    itemData.append("writeUps", writeUps);

    try {
      const response = await axiosInstance.post(api, itemData);
      if (response) {
        triggerFetch();
        setShowCreateForm(false);
        setIsDisabled(false);
      }
    } catch (error) {
      console.error("Error while posting data:", error);
      
      setError(error.response?.data?.message || "An error occurred. Please try again.");
      
      setIsDisabled(false);
    }
  }

  const formattedOptions =
    types?.map((option) => ({
      label: option,
      value: option,
    })) || [];

  return (
    <div className="crate_form">
      <Form onSubmit={handleSubmit}>

      <Select
          options={formattedOptions}
          onChange={(selected) => setType(selected?.value || "")}
          placeholder="Select Work Type"
        />

        <ImageInput
          fieldId="1"
          state={image}
          setState={setImage}
          allowCreateImage
          
        >
          Upload Card Image
        </ImageInput>


        <ShortTextInput
          label={"Card Image Title"}
          value={cardImageTitle}
          setState={setCardImageTitle}
          placeholder={"Enter Card Image Title"}
        />

        <LongTextInput
          label={"Card Image Description"}
          value={cardImageDescription}
          setState={setCardImageDescription}
          placeholder={"Enter Card Image Description"}
        />


        <ImageInput
          fieldId="2"
          state={bannerImage}
          setState={setBannerImage}
          allowCreateImage
        >
          Upload Banner Image
        </ImageInput>

    
        <ShortTextInput
          label={"Title"}
          value={title}
          setState={setTitle}
          placeholder={"Enter Title"}
        />

        <ShortTextInput
          label={"Client"}
          value={client}
          setState={setClient}
          placeholder={"Enter Client"}
        />

        <ShortTextInput
          label={"Category"}
          value={category}
          setState={setCategory}
          placeholder={"Enter Category"}
        />

        <ImageInput
          fieldId="3"
          state={thumbnailImage}
          setState={setThumbnailImage}
          allowCreateImage
        >
          Upload Thumbnail Image
        </ImageInput>

        
        <ShortTextInput
          label={"Thumbnail Link"}
          value={thumbnailLink}
          setState={setThumbnailLink}
          placeholder={"Enter Thumbnail Link"}
        />

        <ShortTextInput
          label={"WriteUps Heading Text"}
          value={writeUpsHeadingText}
          setState={setWriteUpsHeadingText}
          placeholder={"Enter WriteUps Heading Text"}
        />

        <ShortTextInput
          label={"WriteUps Sub Text"}
          value={writeUpsSubText}
          setState={setWriteUpsSubText}
          placeholder={"Enter WriteUps Sub Text"}
        />

        <CustomEditor label="WriteUps" data={writeUps} setState={setWriteUps} />


          {error && <p style={{ color: "red" }}>{error}</p>}
        <FormSubmitButton text="Create Work"  disabled={isDisabled} />
      </Form>
    </div>
  );
}

export default CreateWork;











// import { useState } from "react";
// import Form from "../../../Partials/Forms/Form";
// import ImageInput from "../../../Partials/Forms/FormInputs/ImageInput/ImageInput";
// import FormSubmitButton from "../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
// import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";
// import Select from "react-select"
// function CreateWork({ api, setShowCreateForm, triggerFetch, types }) {
//   const [image, setImage] = useState("");
//   const [type, setType] = useState('');


//   // const { triggerFetch } = useContext(LayoutContext)
//   const axiosInstance = useAxiosInstance();

//   async function handleSubmit(e) {
//     e.preventDefault();

//     const itemData = new FormData();
//     itemData.append("type", type);
//     itemData.append("image", image);
//     console.log(api, itemData);

//     const response = await axiosInstance.post(api, itemData);
//     console.log(response);
//     if (response) {
//         triggerFetch()
//         setShowCreateForm(false)
//     }
//   }
//   // 
//   const formattedOptions = types?.map(option => ({
//     label: option,
//     value: option,
//   })) || [];


//   return (
//     <div className="crate_form">
//       <Form onSubmit={handleSubmit}>
//         <ImageInput
//           fieldId="1"
//           state={image}
//           setState={setImage}
//           allowCreateImage
//         >
//           Upload Image
//         </ImageInput>
        
//         <Select
//         options={formattedOptions}
//         onChange={(selected) => setType(selected?.value || '')}
//         placeholder="Select an option"
//       />
//         <FormSubmitButton text="create work" />
//       </Form>
//     </div>
//   );
// }

// export default CreateWork;



