import {  useState } from "react";
import { IconThreeDots } from "antopolis-react-icons";
import { useGetInfo } from "antopolis-react-utils/hooks";

import { useUseOrientation } from "../../../../Utilities/Hooks/useUseOrientation";
import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";
import { CreateButton } from "../../../Partials/Layout/CRUD/CreateButton/CreateButton";
import { CRUDTable } from "../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { ImageCell } from "../../../Partials/Layout/CRUD/ImageCell/ImageCell";
import { EditButton, ViewButton } from "../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { Modal } from "../../../Partials/Elements/Modal/Modal";

import CreateService from "./CreateService";
import ViewService from "./ViewService";
import UpdateService from "./UpdateService";

function OurServices({  api }) {

  const [showActionButton, setShowActionButton] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);

  const [targetID, setTargetID] = useState(null);

  const { portrait } = useUseOrientation();
  const axiosInstance = useAxiosInstance();
// fetch services
// fetch services
// fetch services
// fetch services
  const { info: services } = useGetInfo({
    axiosInstance: axiosInstance,
    api:api,
    toggleFetch
  });

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <div className="screen_wrapper">
      <div className="screen_header" >
        <h1 className="screen_heading">
          Services ({services?.length} in total)
        </h1>
        <CreateButton
          screenTopicSingular={"Service"}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable >
        <CRUDTableHeader>
          <CRUDth th="Image" />
          <CRUDth th="Title" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {services?.map((service) => (
            <tr key={service._id}>
              <ImageCell imgSrc={service.image} />
              <td>{service.title}</td>
              <td>
                <div className="action_buttons_wrapper">
                  <button
                    className="three_dots"
                    onClick={() => {
                      setShowActionButton(true);
                      setTargetID(service._id);
                    }}
                  >
                    <IconThreeDots />
                  </button>
                  {!portrait && (
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={service._id}
                        setTargetID={setTargetID}
                      />
                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={service._id}
                        setTargetID={setTargetID}
                      />
                    </div>
                  )}
                  {portrait && showActionButton && targetID === service._id && (
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={service._id}
                        setTargetID={setTargetID}
                      />
                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={service._id}
                        setTargetID={setTargetID}
                        setShowActionButton={setShowActionButton}
                      />
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>

      </CRUDTable>

      {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Create service`}
          setShowModalContent={setShowCreateForm}
        >
          
          <CreateService
            setShowCreateForm={setShowCreateForm}
            triggerFetch={triggerFetch}
            api={api}
          />
        </Modal>
      )}
      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update service`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateService
            setShowUpdateForm={setShowUpdateForm}
            targetID={targetID}
            triggerFetch={triggerFetch}
            api={api}
          />
        </Modal>
      )}

      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View services`}
          setShowModalContent={setShowViewSection}
        >
          <ViewService targetID={targetID} api={api} />
        </Modal>
      )}

    </div>
  );
}

export default OurServices;
