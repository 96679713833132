
import { useState } from "react";
import { IconThreeDots } from "antopolis-react-icons";
import { useGetInfo } from "antopolis-react-utils/hooks";
import { useUseOrientation } from "../../../../Utilities/Hooks/useUseOrientation";
import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";
import { CRUDTable } from "../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { EditButton, ViewButton } from "../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { Modal } from "../../../Partials/Elements/Modal/Modal";
import UpdateSubscriptionRequest from "./UpdateSubscriptionRequest";
import ViewSubscriptionRequest from "./ViewSubscriptionRequest";
import { SUBSCRIPTION_REQUESTS_API } from "../../../../Utilities/APIs/SubscriptionAPIs/SubscriptionAPIs";
import FilterSelect from "../../../Partials/Forms/FilterSelect/FilterSelect";

function SubscriptionRequest() {
  const [showActionButton, setShowActionButton] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [filter, setFilter] = useState("subscription");

  const { portrait } = useUseOrientation();
  const axiosInstance = useAxiosInstance();

  // Fetch subscription requests
  const { info: subscriptionRequests } = useGetInfo({
    axiosInstance: axiosInstance,
    api: SUBSCRIPTION_REQUESTS_API,
    queryParams: { filter },
    toggleFetch,
  });

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <div className="screen_wrapper">
      <div className="screen_header">
        <h1 className="screen_heading">
          {filter === "subscription" ? "Subscription Requests" : "Quote Requests"} ({subscriptionRequests?.length} in total)
        </h1>
        <FilterSelect filter={filter} setFilter={setFilter} isQuoteRequest={true} />
      </div>

      <CRUDTable>
        <CRUDTableHeader>
          <CRUDth th="Name" />
          <CRUDth th="Email" />
          {/* <CRUDth th="Phone" /> */}
          <CRUDth th="Status" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {subscriptionRequests?.map((request) => (
            <tr key={request._id}>
              <td>{request.name}</td>
              <td>{request.email}</td>
              {/* <td>{request.phone}</td> */}
              <td>{request.status}</td>
              <td>
                <div className="action_buttons_wrapper">
                  <button
                    className="three_dots"
                    onClick={() => {
                      setShowActionButton(true);
                      setTargetID(request._id);
                    }}
                  >
                    <IconThreeDots />
                  </button>
                  {!portrait && (
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={request._id}
                        setTargetID={setTargetID}
                      />
                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={request._id}
                        setTargetID={setTargetID}
                      />
                    </div>
                  )}
                  {portrait && showActionButton && targetID === request._id && (
                    <div className="action_buttons">
                      <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={request._id}
                        setTargetID={setTargetID}
                      />
                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={request._id}
                        setTargetID={setTargetID}
                        setShowActionButton={setShowActionButton}
                      />
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </CRUDTable>

      {/* Update Form Modal */}
      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update ${filter === 'subscription' ? 'Subcription' : 'Quote'} Request`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateSubscriptionRequest
            setShowUpdateForm={setShowUpdateForm}
            targetID={targetID}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}

      {/* View Section Modal */}
      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View ${filter === 'subscription' ? 'Subcription' : 'Quote'} Request`}
          setShowModalContent={setShowViewSection}
        >
          <ViewSubscriptionRequest targetID={targetID} filter={filter} />
        </Modal>
      )}
    </div>
  );
}

export default SubscriptionRequest;


