import { useContext, useEffect, useState } from "react";
import { useGetInfo } from "antopolis-react-utils/hooks";
import Form from "../../../Partials/Forms/Form";
import ImageInput from "../../../Partials/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";
import FilterSelect from "../../../Partials/Forms/FilterSelect/FilterSelect";

function UpdateClient({ api, setShowUpdateForm, targetID, triggerFetch }) {
  const [image, setImage] = useState("");
  const [isActive, setIsActive] = useState('active');

  // const { triggerFetch } = useContext(LayoutContext)
  const axiosInstance = useAxiosInstance();

  const { info: client } = useGetInfo({
    axiosInstance: axiosInstance,
    api: api + "/" + targetID,
  });

  useEffect(() => {
    if (client) {
      setImage(client.image);
      setIsActive(client.isActive == true ? 'active' : 'archive');
    }
  }, [client]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();
    itemData.append("isActive",  isActive);
    itemData.append("image", image);

    const response = await axiosInstance.patch(api + "/" + targetID, itemData);
    console.log(response,'res')
    if (response) {
      triggerFetch();
      setShowUpdateForm(false);
    }
  }
  return (
    <div className="crate_form">
      <Form onSubmit={handleSubmit}>
        <ImageInput
          fieldId="1"
          state={image}
          setState={setImage}
          allowUpdateImage
        >
          Upload Image
        </ImageInput>
        <FilterSelect filter={isActive} setFilter={setIsActive}/>
        <FormSubmitButton text="Update service" />
      </Form>
    </div>
  );
}

export default UpdateClient;