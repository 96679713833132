import { useState } from "react";
import { useParams } from "react-router-dom";
import { IconThreeDots } from "antopolis-react-icons";
import { useGetInfo } from "antopolis-react-utils/hooks";

import CreateWorkAsset from "./CreateWorkAsset";
import UpdateWorkAsset from "./UpdateWorkAsset";
import ViewWorkAsset from "./ViewWorkAsset";
import { Modal } from "../../../../Partials/Elements/Modal/Modal";
import { OURWORKS_ASSETS_API } from "../../../../../Utilities/APIs/OurWorksAPIs/OurWorksAPIs";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import { useUseOrientation } from "../../../../../Utilities/Hooks/useUseOrientation";
import { CreateButton } from "../../../../Partials/Layout/CRUD/CreateButton/CreateButton";
import { CRUDTable } from "../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { ImageCell } from "../../../../Partials/Layout/CRUD/ImageCell/ImageCell";
import { EditButton, ViewButton } from "../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";

function WorkAssets() {
    const { workID } = useParams()
    const [showActionButton, setShowActionButton] = useState(false);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [showViewSection, setShowViewSection] = useState(false);
    const [toggleFetch, setToggleFetch] = useState(false);

    const [targetID, setTargetID] = useState(null);

    const { portrait } = useUseOrientation();
    const axiosInstance = useAxiosInstance();

    const { info: assets } = useGetInfo({
        axiosInstance: axiosInstance,
        api: OURWORKS_ASSETS_API  + '/getWorkAssets',
        queryParams: { work: workID },
        toggleFetch,
    });

    function triggerFetch() {
        setToggleFetch((prevState) => !prevState);
    }

    return (
        <div className="screen_wrapper">
            <div className="screen_header">
                <h1 className="screen_heading">
                    Work Assets ({assets?.length} in total)
                </h1>
                <CreateButton
                    screenTopicSingular={"Asset"}
                    setShowCreateForm={setShowCreateForm}
                />
            </div>
            <CRUDTable>
                <CRUDTableHeader>
                    <CRUDth th="Image" />
                    <CRUDth th="Title" />
                    <CRUDth th="Actions" />
                </CRUDTableHeader>
                <tbody>
                    {assets?.map((asset) => (
                        <tr key={asset._id}>
                            <ImageCell imgSrc={asset?.image} />
                            <td>{asset.title}</td>
                            <td>
                                <div className="action_buttons_wrapper">
                                    <button
                                        className="three_dots"
                                        onClick={() => {
                                            setShowActionButton(true);
                                            setTargetID(asset._id);
                                        }}
                                    >
                                        <IconThreeDots />
                                    </button>
                                    {!portrait && (
                                        <div className="action_buttons">
                                            <ViewButton
                                                setShowViewSection={setShowViewSection}
                                                targetID={asset._id}
                                                setTargetID={setTargetID}
                                            />
                                            <EditButton
                                                setShowUpdateForm={setShowUpdateForm}
                                                targetID={asset._id}
                                                setTargetID={setTargetID}
                                            />
                                        </div>
                                    )}
                                    {portrait && showActionButton && targetID === asset._id && (
                                        <div className="action_buttons">
                                            <ViewButton
                                                setShowViewSection={setShowViewSection}
                                                targetID={asset._id}
                                                setTargetID={setTargetID}
                                            />
                                            <EditButton
                                                setShowUpdateForm={setShowUpdateForm}
                                                targetID={asset._id}
                                                setTargetID={setTargetID}
                                                setShowActionButton={setShowActionButton}
                                            />
                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </CRUDTable>

            {showCreateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Create Asset`}
                    setShowModalContent={setShowCreateForm}
                >
                    <CreateWorkAsset
                        setShowCreateForm={setShowCreateForm}
                        triggerFetch={triggerFetch}
                        workID={workID}
                    />
                </Modal>
            )}

            {showUpdateForm && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`Update Asset`}
                    setShowModalContent={setShowUpdateForm}
                >
                    <UpdateWorkAsset
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={targetID}
                        triggerFetch={triggerFetch}
                        workID={workID}
                    />
                </Modal>
            )}

            {showViewSection && (
                <Modal
                    extraClass={"small"}
                    modalHeading={`View Asset`}
                    setShowModalContent={setShowViewSection}
                >
                    <ViewWorkAsset
                        targetID={targetID}
                        workID={workID} />
                </Modal>
            )}
        </div>
    );
}

export default WorkAssets;
