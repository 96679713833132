import { useState } from "react";
import { IconThreeDots } from "antopolis-react-icons";
import { useGetInfo } from "antopolis-react-utils/hooks";
import { useUseOrientation } from "../../../../Utilities/Hooks/useUseOrientation";
import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";
import { CreateButton } from "../../../Partials/Layout/CRUD/CreateButton/CreateButton";
import { CRUDTable } from "../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { EditButton, ViewButton } from "../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { Modal } from "../../../Partials/Elements/Modal/Modal";
import CreateServicePackageItem from "./CreateServicePackageItem";
import { ShortTextCell } from "../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell";
import UpdateServicePackageItem from "./UpdateservicepackageItem";
import { SERVICES_PACKAGE_ITEMS_API, } from "../../../../Utilities/APIs/ServicePackagesAPIs/ServicePackagesAPIs";
import { formatValue } from "../../../../Utilities/HelperFunctions/formatValue";

function ServicePackageItems() {

  const [showActionButton, setShowActionButton] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [toggleFetch, setToggleFetch] = useState(false);

  const { portrait } = useUseOrientation();
  const axiosInstance = useAxiosInstance();

  // fetch package items
  const { info: packageItems } = useGetInfo({
    axiosInstance: axiosInstance,
    api: SERVICES_PACKAGE_ITEMS_API, // Assuming items endpoint is structured this way
    toggleFetch,
  });

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <div className="screen_wrapper">
      <div className="screen_header">
        <h1 className="screen_heading">
          Service Package Items ({packageItems?.length} in total)
        </h1>
        <CreateButton
          screenTopicSingular={"Service Package Item"}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable>
        <CRUDTableHeader>
          <CRUDth th={"Name"} />
          <CRUDth th={"Lite Amount"} />
          <CRUDth th={"Essential Amount"} />
          <CRUDth th={"Advanced Amount"} />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {packageItems?.map((item) => (
            <tr key={item._id}>
              <ShortTextCell text={item.name} />
              <ShortTextCell text={formatValue(item?.liteAmount)} />
              <ShortTextCell text={formatValue(item?.essentialAmount)} />
              <ShortTextCell text={formatValue(item?.advancedAmount)} />
              <td>
                <div className="action_buttons_wrapper">
                  <button
                    className="three_dots"
                    onClick={() => {
                      setShowActionButton(true);
                      setTargetID(item._id);
                    }}
                  >
                    <IconThreeDots />
                  </button>
                  {!portrait && (
                    <div className="action_buttons">
                      {/* <ViewButton
                        setShowViewSection={setShowViewSection}
                        targetID={item._id}
                        setTargetID={setTargetID}
                      /> */}
                      <EditButton
                        setShowUpdateForm={setShowUpdateForm}
                        targetID={item._id}
                        setTargetID={setTargetID}
                      />
                    </div>
                  )}
                  {portrait &&
                    showActionButton &&
                    targetID === item._id && (
                      <div className="action_buttons">
                        {/* <ViewButton
                          setShowViewSection={setShowViewSection}
                          targetID={item._id}
                          setTargetID={setTargetID}
                        /> */}
                        <EditButton
                          setShowUpdateForm={setShowUpdateForm}
                          targetID={item._id}
                          setTargetID={setTargetID}
                          setShowActionButton={setShowActionButton}
                        />
                      </div>
                    )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </CRUDTable>

      {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Create Service Package Item`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateServicePackageItem
            setShowCreateForm={setShowCreateForm}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}
      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Service Package Item`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateServicePackageItem
            setShowUpdateForm={setShowUpdateForm}
            targetID={targetID}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}
    </div>
  );
}

export default ServicePackageItems;
