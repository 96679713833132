import NumberInput from "../../../Partials/Forms/FormInputs/NumberInput/NumberInput";
import ShortTextInput from "../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import SwitchInput from "../../../Partials/Forms/FormInputs/SwitchInput/SwitchInput";

function InputFieldByAmountType({ type, value, onChange, label }) {
  if (type === 'boolean') {
    return (
      <SwitchInput
        label={label}
        toggleSwitch={(checked) => onChange(checked)}
        checked={Boolean(value)}
      />
    );
  }
  
  if (type === 'number') {
    return (
      <NumberInput
        label={label}
        value={value}
        setState={(val) => onChange(val)}
        placeholder={`Enter ${label}`}
      />
    );
  }
  
  if (type === 'string') {
    return (
      <ShortTextInput
        label={label}
        value={value}
        setState={(val) => onChange(val)}
        placeholder={`Enter ${label}`}
      />
    );
  }
  
  return null;
}

export default InputFieldByAmountType;
