import { useGetInfo } from "antopolis-react-utils/hooks";
import { useAxiosInstance } from "../../../../Utilities/Hooks/useAxiosInstance";
import { Image } from "../../../Partials/Elements/Image/Image";

function ViewWork({ targetID, api }) {
  const axiosInstance = useAxiosInstance();

  const { info: work } = useGetInfo({
    axiosInstance: axiosInstance,
    api: api + "/" + targetID,
  });

  return (
    <div className="crud_view_content">
      {work && (
        <>

          <h1>Card Image</h1>
          <Image imgLink={work.image} imgAlt={"Main Image"} className={""} />


          <h1>Card Image Title</h1>
          <p>{work?.cardImageTitle}</p>

          <h1>Card Image Description</h1>
          <p>{work?.cardImageDescription}</p>

          <h1>Banner Image</h1>
          <Image imgLink={work?.bannerImage} imgAlt={"Banner Image"} className={""} />

          <h1>Title</h1>
          <p>{work?.title}</p>

          <h1>Type</h1>
          <p>{work?.type}</p>

          {/* Client */}
          <h1>Client</h1>
          <p>{work?.client}</p>

          {/* Category */}
          <h1>Category</h1>
          <p>{work?.category}</p>

          <h1>Thumbnail Image</h1>
          <Image imgLink={work?.thumbnailImage} imgAlt={"Thumbnail Image"} className={""} />

          {/* Thumbnail Link */}
          <h1>Thumbnail Link</h1>
          <p>
            <a
              href={work?.thumbnailLink}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "cyan" }}
            >
              {work?.thumbnailLink}
            </a>
          </p>

          {/* WriteUps Heading Text */}
          <h1>WriteUps Heading Text</h1>
          <p>{work?.writeUpsHeadingText}</p>

          {/* WriteUps Sub Text */}
          <h1>WriteUps Sub Text</h1>
          <p>{work?.writeUpsSubText}</p>

          {/* WriteUps (Rendered HTML) */}
          <h1>WriteUps</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: work.writeUps,
            }}
          ></div>

          {/* Status */}
          <h1>Status</h1>
          <p>{work.isActive ? "Active" : "Inactive"}</p>
        </>
      )}
    </div>
  );
}

export default ViewWork;
