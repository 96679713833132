import { useState } from "react";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import { OURWORKS_ASSETS_API } from "../../../../../Utilities/APIs/OurWorksAPIs/OurWorksAPIs";
import { Form } from "react-router-dom";
import ImageInput from "../../../../Partials/Forms/FormInputs/ImageInput/ImageInput";
import ShortTextInput from "../../../../Partials/Forms/FormInputs/ShortTextInput/ShortTextInput";
import FormSubmitButton from "../../../../Partials/Forms/FormSubmitButton/FormSubmitButton";
import LongTextInput from "../../../../Partials/Forms/FormInputs/LongTextInput/LongTextInput";


function CreateWorkAsset({ workID, setShowCreateForm, triggerFetch }) {
  const [image, setImage] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();

    if (!title || !description || !image) {
      setError("Please fill all the fields")
      return
    }
    setIsDisabled(true)

    const formData = new FormData();
    formData.append("work", workID);
    formData.append("image", image);
    formData.append("title", title);
    formData.append("description", description);

    try {
      const response = await axiosInstance.post(OURWORKS_ASSETS_API, formData);
      if (response) {
        triggerFetch();
        setShowCreateForm(false);
        setIsDisabled(false);
      }
    } catch (error) {
      console.error("Error while posting data:", error);
      setError(error.response?.data?.message || "An error occurred. Please try again.");
      setIsDisabled(false);
    }
  }

  return (
    <div className="crate_form">
      <Form onSubmit={handleSubmit}>
        <ImageInput
          fieldId="1"
          state={image}
          setState={setImage}
          allowCreateImage
        >
          Upload Image
        </ImageInput>

        <ShortTextInput
          label={"Title"}
          value={title}
          setState={setTitle}
          placeholder={"Enter Title"}
        />

        <LongTextInput
          label={"Description"}
          value={description}
          setState={setDescription}
          placeholder={"Enter Description"}
        />
        {error && <p style={{ color: "red" }}>{error}</p>}
        <FormSubmitButton text="Create Asset" disabled={isDisabled} />
      </Form>
    </div>
  );
}

export default CreateWorkAsset;
